import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import GridContainer from '../layout/Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import Section from '../layout/Section';
import GridColumn from '../layout/Grid/GridColumn';
import BlockContent from '@sanity/block-content-to-react';
import SEO from '../components/SEO';
import { IconArrowLeft } from '../components/Icons';

const StyledImageWrapper = styled.div`
  /* width: 10%; */
  .gatsby-image-wrapper {
    width: 85%;
    margin: 0 auto;
    margin-bottom: 2rem;
    /* background-color: red; */
  }
`;

export default function SingleProjectPage({ data: { project } }) {
  const projectImage = project.photos[0];
  // const achievements = project.achievements.map((bullet) =>
  // 			<li key={bullet}>{bullet}</li>
  // 		)}

  // Open external links in new tab
  // https://www.sanity.io/guides/portable-text-internal-and-external-links
  const serializers = {
    marks: {
      link: ({ children, mark }) =>
        mark.blank ? (
          <a href={mark.href} target="_blank" rel="noreferrer">
            {children}
          </a>
        ) : (
          <a href={mark.href}>{children}</a>
        ),
    },
  };

  return (
    <>
      <SEO title={project.customerName} description={project.headline} />
      <Section>
        <GridContainer>
          <GridRow verticalCenter={false}>
            <GridColumn start="1" span="4">
              <StyledImageWrapper>
                <Img
                  // className="project-teaser__photo"
                  fluid={{
                    ...projectImage.photo.asset.fluid,
                    aspectRatio: 1 / 1,
                  }}
                  durationFadeIn={400}
                  alt=""
                  style={
                    {
                      // background: 'red',
                      // width: '85%',
                      // margin: '0 auto',
                      // marginBottom: '2rem',
                    }
                  }
                  imgStyle={{
                    width: '100%',
                    borderRadius: '50%',
                    // objectFit: "cover",
                  }}
                />
                <Img
                  // className="project-teaser__logo"
                  fluid={project.logo.asset.fluid}
                  durationFadeIn={400}
                  alt=""
                  style={{
                    marginBottom: '4rem',
                    // maxWidth: "100%",
                    width: '55%',
                    // margin: '0 auto',
                    // marginBottom: '2rem',
                    // display: "flex",
                    // justifyContent: "center",
                  }}
                  imgStyle={{
                    // width: "100%",
                    objectFit: 'contain',
                  }}
                />
              </StyledImageWrapper>
            </GridColumn>
            <GridColumn start="6" span="6">
              {/* <h1>{project.customerName}</h1> */}
              <h1>{project.headline}</h1>
              {/* <pre>{JSON.stringify(project.details, null, 2)}</pre> */}
              <div>
                {project._rawDetails && (
                  <BlockContent
                    blocks={project._rawDetails}
                    serializers={serializers}
                  />
                )}
              </div>
              <Link to="../#projekte" className="inline-link mt-4">
                {/* <IconArrowLeft size="1.25" /> */}
                Zur Projekt-Übersicht
              </Link>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      customerName
      id
      headline
      achievements
      _rawDetails
      slug {
        current
      }
      photos {
        photo {
          asset {
            fixed(width: 650, height: 650) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 450, maxHeight: 450) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      logo {
        asset {
          fixed(width: 650, height: 650) {
            ...GatsbySanityImageFixed
          }
          fluid(maxWidth: 650) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
